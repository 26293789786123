import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$(function () {
  $('.select').select2({
    width: '100%',
    minimumResultsForSearch: -1
  });

  if ($('#work_main_category_id').length > 0) {
    $('#work_main_category_id').on('select2:select', function (e) {
    })
  }
  if ($('#step1').length > 0 || $('#step2').length > 0) {
    $('select[required="required"]').on('select2:select', function (e) {
      if ($(this).val()) {
        $(this).closest(".form-item").removeClass("error");
        $(this).closest(".form-item").find(".help-block").remove();
      }
    })
  }
  const $filterWrap = $('.filter-wrap');
  if ($filterWrap.length > 0 && $filterWrap.find('.select').length > 0) {
    const $firstSelect = $('#first-select');
    const $secondSelect = $('#second-select');
    const $radios = $('.filter-wrap input[type="radio"]');
    $firstSelect.on('select2:select', function (e) {
      
      
      if (e.params.data.id) {
        $secondSelect.val('').trigger('change');
        $radios.prop('checked', false);
        $('#jury-submit').click();
      }else{
        $firstSelect.val('').trigger('change');
        $secondSelect.val('').trigger('change');
        $radios.prop('checked', false);
        $('#jury-submit').click();

      }

    });

    $secondSelect.on('select2:select', function (e) {
      $radios.prop('checked', false);
      $('#jury-submit').click();

    });
  }

  if ($('.score-result-container').length > 0) {
    $('#selectParticipate').on('select2:select', function (e) {
      window.location.href = `/goldenpin/zh-TW/score_results?participate_year_id=${e.params.data.id}`
    })
    $('#score_setting').on('select2:select', function (e) {
      window.location.href = `/goldenpin/zh-TW/score_results?participate_year_id=${$('#selectParticipate').val()}&&score_setting_id=${e.params.data.id}`
    })
  }
})
